import { withTheme } from '@mui/styles';
import styled from 'styled-components';

import theme from 'src/theme';

const FooterStyle = withTheme(styled.div`
  width: 100%;
  bottom: 0px;
  left: 0px;
  right: 0px;
  margin-top: auto;

  .footer-bottom {
    background-color: #f5f6f7;
    width: 100%;
  }
  .copyright {
    padding: 19px 0;
    width: 100%;
    margin: auto;
    max-width: 1150px;
    text-align: center;
    color: ${theme.palette.text.secondary};
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    .policy {
      color: ${theme.palette.primary.main};
      cursor: pointer;
    }
    p,
    a {
      font-size: 18px;
    }
    p:first-child {
      margin-right: 8px;
    }
  }

  .app-button-desktop {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    padding-top: 16px;
    img {
      cursor: pointer;
    }

    .icon-google-play {
      width: 100%;
      height: 27px;
    }
    .icon-app-store {
      width: 100%;
      height: 27px;
    }
  }
  .app-button-mobile {
    display: none;
  }
  .logo-image {
    width: 157px;
  }

  .icon-text {
    display: flex;
    align-items: center;
    white-space: nowrap;
    svg,
    img {
      margin-right: 8px;
    }
  }
  .facebook-icon {
    margin-right: 8px;
    width: 18px;
    height: 18px;
  }

  .footer-container {
    padding: 2rem 0;
    width: 100%;
    margin: auto;
    max-width: 1150px;
    .MuiGrid-item {
      padding-left: 0px;
    }
  }

  .social-hours {
    white-space: pre-line;
  }

  .footer-section {
    display: flex;
    flex-direction: column;
    padding: 0.5rem;
    line-height: 22px;
    font-size: 18px;
    font-family: Lanna;
    a {
      cursor: pointer;
      color: ${theme.palette.primary.main};
    }
    span {
      color: ${theme.palette.primary.main};
    }
  }

  .partners {
    display: flex;
    justify-content: center;
    width: 100%;
    align-items: center;
    flex-wrap: wrap;
    img {
      height: 28px;
      width: auto;
      padding: 0 1rem;
    }
    .icon-amazon {
      height: 47px;
    }
  }
  .user-agreement {
    margin-left: 8px;
    color: ${theme.palette.primary.main};
  }
  .user-agreement.link {
    font-size: 18px;
    cursor: pointer;
    white-space: nowrap;
  }
  .overview {
    color: #222222;
  }

  ${(props: any) => props.theme.breakpoints.up('lg')} {
    .MuiGrid-item {
      padding: 0px;
    }

    .footer-section {
      display: flex;
      flex-direction: column;
      height: 100%;
      padding: 0.5rem;
      line-height: 22px;
      font-size: 18px;
    }
    .footer-section.first {
      justify-content: space-between;
    }
    .footer-list {
      margin: 0px;
      margin-top: 24px;
      color: ${theme.palette.primary.main};
    }
    .title-app-download {
      color: #222222;
      width: 100vh;
      margin-bottom: 1rem;
    }
  }

  ${(props: any) => props.theme.breakpoints.down('lg')} {
    .footer-section {
      display: flex;
      flex-direction: column;
      padding: 0.5rem;
      line-height: 22px;
      font-size: 18px;
    }
    .contact-us {
      margin-top: 30px;
    }
    .title-app-download {
      color: #222222;
      width: 100vh;
      margin-bottom: 1rem;
    }
    .footer-list {
      margin: 0px;
      margin-top: 24px;
      color: ${theme.palette.primary.main};
    }
  }
  ${(props: any) => props.theme.breakpoints.down('md')} {
    .footer-container {
      padding: 2rem 0.5rem 0px 0.5rem;
    }
    .MuiGrid-item {
      padding: 0px;
    }
    .partners {
      img {
        height: 14px;
        margin: 0px;
        padding: 0 0.25rem;
      }
      .icon-amazon {
        height: 18px;
      }
    }
    .footer-section {
      display: flex;
      flex-direction: column;
      padding: 0.5rem;
      line-height: 22px;
      font-size: 18px;
    }
  }

  ${(props: any) => props.theme.breakpoints.down('sm')} {
    .app-button-desktop {
      display: none;
    }
    .app-button-mobile {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;
      margin-top: 8px;
      margin-bottom: 16px;
      img {
        cursor: pointer;
      }

      .icon-google-play {
        width: 100%;
        height: 44px;
      }
      .icon-app-store {
        width: 100%;
        height: 44px;
      }
    }
    .footer-list {
      margin: 0px;
      color: ${theme.palette.primary.main};
    }

    .contact-us {
      margin-top: 0px;
    }

    .title-app-download {
      color: #222222;
      width: 100%;
      text-align: center;
    }
    .copyright p:first-child {
      margin-right: 0px;
    }
  }
`);

export default FooterStyle;
